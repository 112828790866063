(function($){
    "use strict"; // Start of use strict

    /* ---------------------------------------------
     Scripts initialization
     --------------------------------------------- */

    function debounce(func, wait) {
        let timeout;
        return function(...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    }

    function getCookie(cname) {
        const name = cname + "=";
        const ca = document.cookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function setCookie(cname, cValue, expireDays) {
        const d = new Date();
        d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cValue + ";" + expires + ";path=/";
    }

    function getQueryParams() {
        const params = {};
        const queryString = window.location.search.substring(1);
        const queryArray = queryString.split("&");

        queryArray.forEach(param => {
            const [key, value] = param.split("=");
            params[decodeURIComponent(key)] = decodeURIComponent(value || "");
        });

        return params;
    }

    function mergeQueryParams(url, params) {
        const baseUrl = window.location.origin;
        const urlObj = new URL(url, baseUrl);
        Object.keys(params).forEach(key => {
            if (key) {
                urlObj.searchParams.set(key, params[key]);
            }
        });
        return urlObj.toString();
    }

    function init_tr() {
        const params = getQueryParams();
        const cookieKey = "queryParams";
        const firstVisit = !getCookie("fVisit");

        if (Object.keys(params).length > 0 && firstVisit) {
            setCookie(cookieKey, JSON.stringify(params), 14); // Save all query params in a single cookie
            setCookie("fVisit", "true", 14); // Mark the first visit
        }

        const cookiesParams = JSON.parse(getCookie(cookieKey) || "{}");

        $(".btn-create").each(function() {
            const currentUrl = $(this).attr("href");
            const newUrl = mergeQueryParams(currentUrl, cookiesParams);
            $(this).attr("href", newUrl);
        });
    }

    $(window).on("load", function(){
        // Page loader
        $(".page-loader div").fadeOut();
        $(".page-loader").delay(200).fadeOut("slow");

        init_text_rotator();
        init_scroll_navigate();

        // Hash menu forwarding
        if (window.location.hash && $(window.location.hash).length) {
            var hash_offset = $(window.location.hash).offset().top;
            $("html, body").animate({
                scrollTop: hash_offset
            });
        }
    });

    $(document).ready(function(){
        init_classic_menu();
        init_lightbox();
        init_services();
        init_shortcodes();
        init_tooltips();
        init_tr();

        // Trigger initial resize and scroll events
        $(window).trigger("resize");
        $(window).trigger("scroll");

        // Debounced resize handler
        $(window).on("resize", debounce(function(){
            init_classic_menu_resize();
            // 100vh height on mobile devices
            var vh = $(window).height() * 0.01;
            $("html").css("--vh", vh + "px");
        }, 200));
    });



    /* --------------------------------------------
     Platform detect
     --------------------------------------------- */

    var mobileTest;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        mobileTest = true;
        $("html").addClass("mobile");
    }
    else {
        mobileTest = false;
        $("html").addClass("no-mobile");
    }
    var mozillaTest;
    if (/mozilla/.test(navigator.userAgent)) {
        mozillaTest = true;
    }
    else {
        mozillaTest = false;
    }
    var safariTest;
    if (/safari/.test(navigator.userAgent)) {
        safariTest = true;
    }
    else {
        safariTest = false;
    }

    // Detect touch devices
    if (!("ontouchstart" in document.documentElement)) {
        document.documentElement.className += " no-touch";
    } else {
        document.documentElement.className += " touch";
    }

    /* ---------------------------------------------
     Swiper & Magnific Popup Optimizations
     --------------------------------------------- */
    document.addEventListener("DOMContentLoaded", function () {
        document.querySelector(".testimonial-swiper").style.visibility = "hidden"; // Hide until fully initialized

        requestAnimationFrame(() => {
            initTestimonialSwiper();
            document.querySelector(".testimonial-swiper").style.visibility = "visible"; // Show when ready
        });
    });

    function initTestimonialSwiper() {
        var swiper = new Swiper(".testimonial-swiper", {
            effect: "coverflow",
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: "auto",
            initialSlide: 1,
            speed: 300,
            coverflowEffect: {
                rotate: 0,
                stretch: 125,
                depth: 300,
                modifier: 1,
                slideShadows: false,
            },
            pagination: {
                el: ".testimonial-pagination",
                clickable: true,
                bulletClass: "swiper-pagination-line",
                bulletActiveClass: "swiper-pagination-line-active",
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    coverflowEffect: { stretch: 0 },
                },
                768: {
                    slidesPerView: "auto",
                    coverflowEffect: { stretch: 125 },
                }
            }
        });

        swiper.on("slideChange", function () {
            document.querySelectorAll(".testimonial-slide picture img").forEach((img) => {
                img.style.opacity = "0.7"; // Slightly fade inactive slides
            });

            let activeSlide = document.querySelector(".swiper-slide-active picture img");
            if (activeSlide) {
                activeSlide.style.opacity = "1"; // Full visibility for active slide
            }
        });
    }

// ✅ Fix Magnific Popup Video Size (Only for Testimonial Videos)
    document.querySelectorAll(".vertical-lightbox").forEach((lightbox) => {
        lightbox.addEventListener("click", function (event) {
            event.preventDefault();
            const videoUrl = this.getAttribute("data-video");
            if (!videoUrl) {
                console.error("Error: Video URL is missing or undefined.");
                return;
            }

            $.magnificPopup.open({
                items: {
                    src: `
                    <div class="testimonial-video-container">
                        <video id="popupVideo" controls autoplay muted playsinline>
                            <source src="${videoUrl}" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                `,
                    type: "inline"
                },
                mainClass: "mfp-fade",
                closeOnBgClick: true,
                callbacks: {
                    open: function () {
                        let popupVideo = document.getElementById("popupVideo");
                        if (popupVideo) {
                            popupVideo.style.width = "100%";
                            popupVideo.style.height = "auto";
                            popupVideo.style.maxHeight = "740px";
                        }
                        // Add class to fix popup styling only for testimonial videos
                        document.querySelector(".mfp-content").classList.add("testimonial-popup");
                    },
                    close: function () {
                        document.getElementById("popupVideo").pause();
                        document.querySelector(".mfp-content").classList.remove("testimonial-popup");
                    }
                }
            });
        });
    });

    /* ---------------------------------------------
     Sections helpers
     --------------------------------------------- */

    // Sections backgrounds
    var pageSection = $(".home-section, .page-section, .small-section, .split-section");
    pageSection.each(function(indx){
        if ($(this).attr("data-background")){
            $(this).css("background-image", "url(" + $(this).data("background") + ")");
        }
    });

    // Function for block height 100%
    function height_line(height_object, height_donor){
        height_object.height(height_donor.height());
        height_object.css({
            "line-height": height_donor.height() + "px"
        });
    }
    function height_line_s(height_object, height_donor){
        height_object.height(height_donor.height());
        height_object.css({
            "line-height": height_donor.height() - 2 + "px"
        });
    }

    // Progress bars
    var progressBar = $(".progress-bar");
    progressBar.each(function(indx){
        $(this).css("width", $(this).attr("aria-valuenow") + "%");
    });


    /* ---------------------------------------------
     Nav panel classic
     --------------------------------------------- */

    var mobile_nav = $(".mobile-nav");
    var desktop_nav = $(".desktop-nav");

    mobile_nav.attr("aria-expanded", "false");

    function init_classic_menu_resize(){

        // Mobile menu max height
        $(".mobile-on .desktop-nav > ul").css("max-height", $(window).height() - $(".main-nav").height() - 20 + "px");

        // Mobile menu style toggle
        if ($(window).width() <= 1024) {
            $(".main-nav").addClass("mobile-on");
            $(".mobile-cart").show();
        }
        else
        if ($(window).width() > 1024) {
            $(".main-nav").removeClass("mobile-on");
            desktop_nav.show();
            $(".mobile-cart").hide();
        }
    }

    function init_classic_menu(){

        if ($(".main-nav").hasClass("transparent")){
            height_line($(".inner-nav > ul > li > a"), $(".main-nav"));
        } else {
            height_line_s($(".inner-nav > ul > li > a"), $(".main-nav"));
        }
        height_line(mobile_nav, $(".main-nav"));
        height_line($(".mobile-cart"), $(".main-nav"));

        // Transpaner menu

        if ($(".main-nav").hasClass("transparent")){
            $(".main-nav").addClass("js-transparent");
        } else if (!($(".main-nav").hasClass("dark"))){
            $(".main-nav").addClass("js-no-transparent-white");
        }

        $(window).scroll(function(){

            if ($(window).scrollTop() > 0) {
                $(".js-transparent").removeClass("transparent");
                $(".main-nav, .nav-logo-wrap .logo, .mobile-nav, .mobile-cart").addClass("small-height");
                $(".light-after-scroll").removeClass("dark");
                $(".main-nav").addClass("body-scrolled");
            }
            else if ($(window).scrollTop() === 0){
                $(".js-transparent").addClass("transparent");
                $(".main-nav, .nav-logo-wrap .logo, .mobile-nav, .mobile-cart").removeClass("small-height");
                $(".light-after-scroll").addClass("dark");
                $(".main-nav").removeClass("body-scrolled");
            }


        });

        // Mobile menu toggle

        mobile_nav.click(function(){

            if (desktop_nav.hasClass("js-opened")) {
                desktop_nav.slideUp("slow", "easeOutExpo").removeClass("js-opened");
                $(this).removeClass("active");
                $(this).attr("aria-expanded", "false");
            }
            else {
                desktop_nav.slideDown("slow", "easeOutQuart").addClass("js-opened");
                $(this).addClass("active");
                $(this).attr("aria-expanded", "true");
                // Fix for responsive menu
                if ($(".main-nav").hasClass("not-top")){
                    $(window).scrollTo(".main-nav", "slow");
                }
            }

        });

        $(document).on("click", function(event){
            if ($(window).width() <= 1024) {
                var $trigger = $(".main-nav");
                if ($trigger !== event.target && !$trigger.has(event.target).length) {
                    desktop_nav.slideUp("slow", "easeOutExpo").removeClass("js-opened");
                    mobile_nav.removeClass("active");
                    mobile_nav.attr("aria-expanded", "false");
                }
            }
        });

        mobile_nav.keydown(function(e){
            if (e.keyCode == 13 || e.keyCode == 32) {
                if (desktop_nav.hasClass("js-opened")) {
                    desktop_nav.slideUp("slow", "easeOutExpo").removeClass("js-opened");
                    $(this).removeClass("active");
                    $(this).attr("aria-expanded", "false");
                }
                else {
                    desktop_nav.slideDown("slow", "easeOutQuart").addClass("js-opened");
                    $(this).addClass("active");
                    $(this).attr("aria-expanded", "true");
                    // Fix for responsive menu
                    if ($(".main-nav").hasClass("not-top")) {
                        $(window).scrollTo(".main-nav", "slow");
                    }
                }
            }
        });

        desktop_nav.find("a:not(.mn-has-sub)").click(function(){
            if (mobile_nav.hasClass("active")) {
                desktop_nav.slideUp("slow", "easeOutExpo").removeClass("js-opened");
                mobile_nav.removeClass("active");
                mobile_nav.attr("aria-expanded", "false");
            }
        });


        // Sub menu

        var mnHasSub = $(".mn-has-sub");
        var mnThisLi;

        mnHasSub.attr({
            "role": "button",
            "aria-expanded": "false",
            "aria-haspopup": "true"
        });

        mnHasSub.click(function(){

            if ($(".main-nav").hasClass("mobile-on")) {
                mnThisLi = $(this).parent("li:first");
                if (mnThisLi.hasClass("js-opened")) {
                    $(this).attr("aria-expanded", "false");
                    mnThisLi.find(".mn-sub:first").slideUp(function(){
                        mnThisLi.removeClass("js-opened");
                    });
                }
                else {
                    $(this).attr("aria-expanded", "true");
                    mnThisLi.addClass("js-opened");
                    mnThisLi.find(".mn-sub:first").slideDown();
                }

                return false;
            }

        });

        mnThisLi = mnHasSub.parent("li");
        mnThisLi.hover(function(){

            if (!($(".main-nav").hasClass("mobile-on"))) {
                $(this).find(".mn-has-sub:first")
                    .attr("aria-expanded", "true")
                    .addClass("js-opened");
                $(this).find(".mn-sub:first").stop(true, true).fadeIn("fast");
            }

        }, function(){

            if (!($(".main-nav").hasClass("mobile-on"))) {
                $(this).find(".mn-has-sub:first")
                    .attr("aria-expanded", "false")
                    .removeClass("js-opened");
                $(this).find(".mn-sub:first").stop(true, true).delay(100).fadeOut("fast");
            }

        });

        /* Keyboard navigation for main menu */

        mnHasSub.keydown(function(e){

            if ($(".main-nav").hasClass("mobile-on")) {
                if (e.keyCode == 13 || e.keyCode == 32) {
                    mnThisLi = $(this).parent("li:first");
                    if (mnThisLi.hasClass("js-opened")) {
                        $(this).attr("aria-expanded", "false");
                        mnThisLi.find(".mn-sub:first").slideUp(function(){
                            mnThisLi.removeClass("js-opened");
                        });
                    }
                    else {
                        $(this).attr("aria-expanded", "true");
                        mnThisLi.addClass("js-opened");
                        mnThisLi.find(".mn-sub:first").slideDown();
                    }

                    return false;
                }
            }

        });

        $(".inner-nav a").focus(function(){
            if (!($(".main-nav").hasClass("mobile-on")) && ($("html").hasClass("no-touch")) && (!($(this).parent("li").find(".mn-sub:first").is(":visible")))) {
                $(this).parent("li").parent().children().find(".mn-has-sub:first")
                    .attr("aria-expanded", "false")
                    .removeClass("js-opened");
                $(this).parent("li").parent().children().find(".mn-sub:first").stop(true, true).delay(100).fadeOut("fast");
            }
        });

        $(".inner-nav a").first().keydown(function(e){
            if (!($(".main-nav").hasClass("mobile-on"))) {
                if (e.shiftKey && e.keyCode == 9) {
                    $(this).parent("li").find(".mn-has-sub:first")
                        .attr("aria-expanded", "false")
                        .removeClass("js-opened");
                    $(this).parent("li").find(".mn-sub:first").stop(true, true).delay(100).fadeOut("fast");
                }
            }
        });

        $(".mn-sub li:last a").keydown(function(e){
            if (!($(".main-nav").hasClass("mobile-on"))) {
                if (!e.shiftKey && e.keyCode == 9) {
                    $(this).parent("li").parent().parent().find(".mn-has-sub:first")
                        .attr("aria-expanded", "false")
                        .removeClass("js-opened");
                    $(this).parent("li").parent().stop(true, true).delay(100).fadeOut("fast");
                }
            }
        });

        $(document).keydown(function(e){
            if (!($(".main-nav").hasClass("mobile-on"))) {
                if (e.keyCode == 27) {
                    if (mnHasSub.parent("li").find(".mn-sub:first li .mn-sub").is(":visible")){
                        mnHasSub.parent("li").find(".mn-sub:first li .mn-has-sub")
                            .attr("aria-expanded", "false")
                            .removeClass("js-opened");
                        mnHasSub.parent("li").find(".mn-sub:first li .mn-sub").stop(true, true).delay(100).fadeOut("fast");
                    } else{
                        mnHasSub.parent("li").find(".mn-has-sub:first")
                            .attr("aria-expanded", "false")
                            .removeClass("js-opened");
                        mnHasSub.parent("li").find(".mn-sub:first").stop(true, true).delay(100).fadeOut("fast");
                    }

                }
            }
        });

        mnHasSub.on("click", function () {
            if ((!($(".main-nav").hasClass("mobile-on"))) && ($("html").hasClass("no-touch"))) {
                if (!($(this).hasClass("js-opened"))){
                    $(this).addClass("js-opened");
                    $(this).attr("aria-expanded", "true");
                    $(this).parent("li").find(".mn-sub:first").fadeIn("fast");
                    return false;
                }
                else{
                    $(this).removeClass("js-opened");
                    $(this).attr("aria-expanded", "false");
                    $(this).parent("li").find(".mn-sub:first").fadeOut("fast");
                    return false;
                }
            }
        });

    }


    /* ---------------------------------------------
     Scroll navigation
     --------------------------------------------- */

    function init_scroll_navigate(){


        var sections = $(".home-section, .split-section, .page-section");
        var menu_links = $(".scroll-nav li a");

        $(window).scroll(function(){

            sections.filter(":in-viewport:first").each(function(){
                var active_section = $(this);
                var active_link = $('.scroll-nav li a[href="#' + active_section.attr("id") + '"]');
                menu_links.removeClass("active");
                active_link.addClass("active");
            });

        });

    }


    /* ---------------------------------------------
     Lightboxes
     --------------------------------------------- */

    function init_lightbox(){

        // Works Item Lightbox
        $(".work-lightbox-link").magnificPopup({
            gallery: {
                enabled: true
            },
            mainClass: "mfp-fade"
        });

        // Other Custom Lightbox
        $(".lightbox-gallery-1").magnificPopup({
            gallery: {
                enabled: true
            },
            mainClass: "mfp-fade"
        });
        $(".lightbox-gallery-2").magnificPopup({
            gallery: {
                enabled: true
            },
            mainClass: "mfp-fade"
        });
        $(".lightbox-gallery-3").magnificPopup({
            gallery: {
                enabled: true
            },
            mainClass: "mfp-fade"
        });
        $(".lightbox-gallery-4").magnificPopup({
            gallery: {
                enabled: true
            },
            mainClass: "mfp-fade"
        });
        $(".lightbox-gallery-5").magnificPopup({
            gallery: {
                enabled: true
            },
            mainClass: "mfp-fade"
        });
        $(".lightbox-gallery-6").magnificPopup({
            gallery: {
                enabled: true
            },
            mainClass: "mfp-fade"
        });
        $(".lightbox-gallery-7").magnificPopup({
            gallery: {
                enabled: true
            },
            mainClass: "mfp-fade"
        });
        $(".lightbox-gallery-8").magnificPopup({
            gallery: {
                enabled: true
            },
            mainClass: "mfp-fade"
        });
        $(".lightbox-gallery-9").magnificPopup({
            gallery: {
                enabled: true
            },
            mainClass: "mfp-fade"
        });
        $(".lightbox-gallery-10").magnificPopup({
            gallery: {
                enabled: true
            },
            mainClass: "mfp-fade"
        });
        $(".lightbox").magnificPopup({
            gallery: {
                enabled: true
            },
            mainClass: "mfp-fade"
        });

    }

    /* -------------------------------------------
     Text Rotator
     --------------------------------------------- */

    function init_text_rotator(){
        $(".text-rotate").each(function(){
            var text_rotator = $(this);
            var text_rotator_cont = text_rotator.html();
            text_rotator.attr("aria-hidden", "true");
            text_rotator.before("<span class='sr-only'>" + text_rotator_cont + "</span>");
            text_rotator.Morphext({
                animation: "fadeIn",
                separator: ",",
                speed: 4000
            });
        });
    }

    /* ---------------------------------------------
     Shortcodes
     --------------------------------------------- */

    function init_shortcodes(){
        // Accordion
        $(".accordion").each(function(){
            var allPanels = $(this).children("dd").hide();
            var allTabs = $(this).children("dt").children("a");
            allTabs.attr("role", "button");
            $(this).children("dd").first().slideDown("easeOutExpo");
            $(this).children("dt").children("a").first().addClass("active");
            $(this).children("dt").children("a").attr("aria-expanded", "false");
            $(this).children("dt").children("a").first().attr("aria-expanded", "true");

            $(this).children("dt").children("a").click(function(){
                var current = $(this).parent().next("dd");
                allTabs.removeClass("active");
                $(this).addClass("active");
                allTabs.attr("aria-expanded", "false");
                $(this).attr("aria-expanded", "true");
                allPanels.not(current).slideUp("easeInExpo");
                $(this).parent().next().slideDown("easeOutExpo");
                return false;
            });

        });

        // Toggle
        var allToggles = $(".toggle > dd").hide();
        var allTabs = $(".toggle > dt > a");
        allTabs.attr({
            "role": "button",
            "aria-expanded": "false"
        });

        $(".toggle > dt > a").click(function(){

            if ($(this).hasClass("active")) {
                $(this).parent().next().slideUp("easeOutExpo");
                $(this).removeClass("active");
                $(this).attr("aria-expanded", "false");
            }
            else {
                var current = $(this).parent().next("dd");
                $(this).addClass("active");
                $(this).attr("aria-expanded", "true");
                $(this).parent().next().slideDown("easeOutExpo");
            }

            return false;
        });
    }

    /* ---------------------------------------------
     Tooltips (bootstrap plugin activated)
     --------------------------------------------- */

    function init_tooltips(){
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl){
            return new bootstrap.Tooltip(tooltipTriggerEl)
        })
    }

    /* ---------------------------------------------
     Services
     --------------------------------------------- */

    function init_services(){
        var services_more;
        var services_title;
        $(".services-item").each(function(){
            services_title = $(this).find(".services-title").html();
            services_more = $(this).find(".services-more > .text-link").html();
            $(this).find(".services-more > .text-link").html(services_more + '<span class="sr-only"> about ' + services_title + '</span>');
        });
    }

})(jQuery); // End of use strict

/* ---------------------------------------------
 Adding aria-hidden to Font Awesome
 icons
 --------------------------------------------- */

(function(){
    let getIcons = document.querySelectorAll('i.fa, i.fab, i.far, i.fas');
    getIcons.forEach(function(iconEach)
    {
        let getIconAttr = iconEach.getAttribute('aria-hidden');
        if (!getIconAttr)
        {
            iconEach.setAttribute('aria-hidden','true');
        }
    });
})();